<template>
    <div class="oa-page">
        <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div>
        <section class="oa-main">
            <!-- <div class="top-banner-wrap">
                <div class="wid1200">
                    <top-banner :bannerData="imgBannerData"></top-banner>
                </div>
            </div> -->

            <div class="top-banner-wrap">
                <div class="wid1200">
                    <div class="top-banner-component">

                        <div class="left-top-banner">
                            <div class="left-top-banner-bg"></div>
                            <h5>{{oaJJ.title}}</h5>
                            <div>
                                <p class="banner-content" v-html="oaJJ.content">
                                </p>
                                <span @click="goIntro">更多></span>
                            </div>

                        </div>
                        <div class="right-top-banner">
                            <top-banner :bannerData="imgBannerData" :pageName="pageName"></top-banner>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wid1200 dynamic-wrap">
                <div class="com-title">
                    <div>
                        <h5>OA资讯</h5>
                    </div>
                    <div>
                        <span @click="goOa('kfhq_dt')">更多<img src="../assets/img/more.png" alt="" /></span>
                    </div>
                </div>

                <div class="dynamic">
                    <ul>
                        <li v-for="item in dynamicData" :key="item.id">
                            <p><a @click="goLink(item)">{{item.title}}</a></p><span>{{item.releaseDate}}</span>
                        </li>
                        <!-- <li>
                            <p>新冠疫情期间大学生网课学习的特点（预印本）</p><span>2020-07-22</span>
                        </li>
                        <li>
                            <p>新冠疫情期间大学生网课学习的特点（预印本）</p><span>2020-07-22</span>
                        </li>
                        <li>
                            <p>新冠疫情期间大学生网课学习的特点（预印本）</p><span>2020-07-22</span>
                        </li>
                        <li>
                            <p>新冠疫情期间大学生网课学习的特点（预印本）</p><span>2020-07-22</span>
                        </li>
                        <li>
                            <p>新冠疫情期间大学生网课学习的特点（预印本）</p><span>2020-07-22</span>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="wid1200 article-wrap">
                <com-article :articleData="articleData" :pageName="pageName" @changeSubject="changeArticleSubject"
                    @changeYear="changeArticleYear" @pageChange="articleChange" />
            </div>
            <div class="wid1200 book-wrap">
                <com-book :bookData="bookData" :pageName="pageName" @changeSubject="changeBookSubject"
                    @changeYear="changeBookYear" @pageChange="bookChange" />

                <!-- this.$emit('changeSubject', this.subject)
             this.$emit('changeYear', this.year) -->

            </div>
            <div class="wid1200 source-wrap">
                <div class="com-title">
                    <div>
                        <h5>其他OA资源</h5>
                    </div>
                    <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
                </div>
                <ul class="source">
                    <li v-for="item in otherSourceData" :key="item.id" @click="download(item)">
                        <span>{{item.mediaType}}</span>
                        <p  :style="{cursor:(item.mediaPath ? 'pointer':'auto')}" @click="download(item)">{{item.title}}</p>
                    </li>
                </ul>
                <div class="pagination-wrap" v-if="otherSourceTotal>0">
                    <el-pagination @current-change="handleChangeOA" :current-page="otherSourceNum"
                        :page-size="otherSourceSize" layout="total, prev, pager, next, jumper" :total="otherSourceTotal">
                    </el-pagination>
                </div>

            </div>

        </section>





        <a id="downLaodAticle" style="display:none;" :href="'/admin/api/article/downLoad?id='+downloadId">下载阅读</a>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
            :append-to-body="trueDate">
            <div class="tips" v-if="codeNum == '1002'">
                <span v-if="paylist.length>0"  style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}} 元，请购买后再操作！</span>
                <span v-else  style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}} 元，账号余额不足请先充值。</span>
            </div>

            <span class="tips  com-tips" v-else-if="tips == '您还未登录，请先登录！'"  style="font-size: 16px;line-height: 30px;">
                您还未登录，请先<span class="com-login-tips"  @click="toLogin">登录</span>！
            </span>

            <span class="tips  com-tips" v-else v-html="tips"></span>

            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="showBuy" v-if="codeNum == '1002'">去购买</el-button>
                <el-button type="small" @click="toLogin" v-if="codeNum == '0'&&!hasPay">请登录</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="dialogBuy" width="30%" :before-close="handleBuyClose"
            :append-to-body="trueDate">
            <div class="tips tipsleft">
                <span  style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}}元</span>
                <p  style="font-size: 16px;line-height: 30px;">支付方式：</p>
                <p v-for="(item, index) in balanceInfo" :key="index"  style="font-size: 16px;line-height: 30px;">
                    <el-radio v-model="radiobuy" :label="item.accounttype"  style="font-size: 16px;line-height: 30px;">
                        {{item.accounttype=='common'?'现金账户余额':'充值卡余额'}}{{item.accountbalance}}</el-radio>
                </p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="handleBuyClose">取消</el-button>
                <el-button type="small" @click="gobuy">支付</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="dialogVisibledow" width="30%" :before-close="handleClosedow"
            :append-to-body="trueDate">
            <span class="tips">{{tipsdow}}</span>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="dialogVisibledow = !dialogVisibledow">确定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import TopBanner from '../components/ComBanner'
// import TopBanner from '../components/TopBanner'
import ComArticle from '../components/ComArticle'
import ComBook from '../components/ComBook'

import img1 from '../assets/img/book.png'

export default {
    name: '',
    components: { BreadCrumb, TopBanner, ComArticle, ComBook },
    data() {
        return {
            pN: '',
            pageName: '开放获取资源',
            bannerData: {
                title: '开放获取简介',
                content: '网课是疫情期间大学课程教学的主要形式。调查表明，直播、复合 型网课是主流。大部分大学生对网课适应、基本满意，但在学习效果判断上态度犹豫。大学生普遍反映，网课在“网络速度”“师生互动”等方面需要改进。网络授课也将对大学学习的形式产生深刻影响，但它毕竟难以取代真正的大学学习生活。尽管疫情的发展还有一定不确定性，从调查结果来看，约... '
            },
            imgBannerData: [],
            dynamicData: [],
            otherSourceData: [],
            otherSourceNum: 1,
            otherSourceSize: 6,
            otherSourceTotal: 0,
            articleData: {
                title: 'OA文章',
                articleList: [
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    }
                ],
                total: 0
            },
            bookData: {
                title: 'OA图书',
                bookList: [
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    }
                ]
            },
            subjectId: '',
            pageNum: 1,
            pageSize: 10,
            oaJJ: {},

            downloadId: '',
            dialogVisible: false,
            tips: '',
            paylist: [],
            balanceInfo: [],
            payPrice: '',
            radiobuy: '',
            codeNum: '',
            trueDate: true,
            hasPay: false,
            dialogVisibledow: false,
            tipsdow: '',
            shareWechat: false,
            dialogBuy: false
        }
    },
    methods: {
        handleChangeOA (val) {
            this.otherSourceNum = val
            this.getData('kfhq_qtzy')
        },
        articleChange(val) {
            this.pageNum = val
            this.getListData('ARTICLE')
        },
        goOa(id) {
            this.$router.push({ path: '/news/n', query: { id: id, pN: encodeURI('OA资讯') } })
        },
        goIntro() {
            this.$router.push({ path: '/oaIntro/o', query: { pN: encodeURI('OA资讯'), id: 'kfhq_gykfhq' } })
        },
        goLink(item) {
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else {
                this.$router.push({ path: '/nD/n', query: { id: item.id, pN: encodeURI(this.pageName) } })
            }
        },
        bookChange(val) {
            this.pageNum = val
            this.getListData('BOOK')
        },
        changeBookSubject(val) {
            //学科
            this.pageNum = 1
            this.bookSubjectId = val
            this.getListData('BOOK')
        },
        changeBookYear(val) {
            //年份
            this.pageNum = 1
            this.bookBookReleaseDate = val
            this.getListData('BOOK')
        },
        changeArticleSubject(val) {
            //学科
            this.pageNum = 1
            this.articleSubjectId = val
            this.getListData('ARTICLE')
        },
        changeArticleYear(val) {
            //年份
            this.pageNum = 1
            this.articleBookReleaseDate = val
            this.getListData('ARTICLE')
        },
        getData(id) {
            var _this = this
            var curParams = {}
            if (id == 'kfhq_qtzy') {
                curParams = {
                    innerCode: id,
                    pageSize: this.otherSourceSize,
                    pageNum: this.otherSourceNum,
                }
            } else {
                curParams = {
                    innerCode: id
                }
            }
            this.$http({
                method: 'get',
                url: '/admin/api/news/list',
                params: curParams
            }).then(res => {
                if (res.data.code == 0) {
                    if (id == 'kfhq_lbt') {
                        //轮播图
                        _this.imgBannerData = res.data.data.rows
                    } else if (id == 'kfhq_dt') {
                        //动态
                        _this.dynamicData = res.data.data.rows
                    } else if (id == 'kfhq_qtzy') {
                        //其他资源
                        _this.otherSourceData = res.data.data.rows
                        _this.otherSourceTotal = res.data.data.total
                        // console.log('otherSourceData', _this.otherSourceData)
                    } else if (id == 'kfhq_jj') {
                        //简介
                        _this.oaJJ = res.data.data.rows[0]
                    }
                }
            })
        },
        getListData(type) {
            var _this = this
            if (this.bookReleaseDate == '全部') {
                this.bookReleaseDate = ''
            }
            var curYear = ''
            var curSubject = ''
            var resourceTypeList = []
            if (type == 'BOOK') {
                curYear = this.bookBookReleaseDate
                curSubject = this.bookSubjectId
                this.pageSize = 10
                resourceTypeList = ['BOOK','WENJI']
            } else if (type == 'ARTICLE') {
                curYear = this.articleBookReleaseDate
                curSubject = this.articleSubjectId
                this.pageSize = 14
                resourceTypeList = ['ARTICLE']
            }

            this.$http({
                method: 'post',
                url: '/admin/api/search/find',
                data: {
                    pageSize: this.pageSize,
                    pageNo: this.pageNum,
                    openAccessStatus: 1,
                    resourceTypeList: resourceTypeList,
                    classifyId: curSubject,
                    bookReleaseDate: curYear
                }
            }).then(res => {
                if (res.data.code == 0) {
                    if (type == 'BOOK') {
                        _this.bookData.total = res.data.data.total
                        _this.bookData.bookList = res.data.data.rows
                    } else if (type == 'ARTICLE') {
                        _this.articleData.total = res.data.data.total
                        _this.articleData.articleList = res.data.data.rows
                    }
                }
            })
        },

        download(item) {
            // console.log('item', item)

            // mediaPath: "/admin/profile/upload/2020/12/23/4a6ff08ff98ab7f34bcd4cb11b4ae2bf.rar,/admin/profile/upload/2020/12/23/4a6ff08ff98ab7f34bcd4cb11b4ae2bf.rar"
            // mediaType: "rar"

            this.downloadId = item.id
            var _this = this
            // _trackData.push(['addaction','期刊主页', '全部下载按钮', {'标题': _this.detail.title}])
            var result = _this.getCheck(2)
            result.then(function(res) {
                    if (res.data.code == 0) {
                        if (item.mediaType == 'pdf') {
                            window.open(item.mediaPath, '_blank')
                        } else {
                            document.getElementById('downLaodAticle').click()
                        }
                    }
                })
                .catch(function(res) {
                    _this.dialogVisible = true
                    if (res.code == 401) {
                        //未登录
                        _this.tips = '您还未登录，请先登录！'
                    }
                    if (res.code == 1002) {
                        //无权限
                        _this.paylist = error.paylist
                        _this.balanceInfo = error.balanceInfo
                        _this.payPrice = error.price
                        _this.tips = '这个资源需要付费，请购买后查看！'
                    }
                    if (res.code == 500) {
                        //统一错误
                        _this.tips = res.msg
                    }
                })
        },
        handleBuyClose() {
            this.dialogBuy = false
        },
        handleClosedow() {
            this.dialogVisibledow = false
        },
        handleClose() {
            this.dialogVisible = false
        },
        showBuy() {
            this.dialogBuy = !this.dialogBuy
        },
        encodeURI2(){
          var url = encodeURIComponent(window.location.origin + encodeURIComponent(window.location.pathname) + window.location.search + window.location.hash)
          console.log(url)
          return url;
        },
        toLogin() {
            window.location.href = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
        },
        gobuy() {
            let _this = this
            _this.winUrl = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
            _this
                .$http({
                    method: 'get',
                    url: '/admin/api/order/submit',
                    params: {
                        contentId: _this.downloadId,
                        payment: _this.radiobuy,
                        resourceType: 'literature'
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                })
                .then(res => {
                    if (res.data.code == 0) {
                        _this.codeNum = 0
                        _this.codeNum2 = false
                        _this.dialogBuy = false
                        _this.hasPay = true
                        _this.tips = '支付完成！'
                    } else {
                        _this.dialogVisibledow = true
                        _this.tipsdow = res.msg
                    }
                })
        },
        getCheck(type) {
            let _this = this
            var promise = new Promise(function(resolve, reject) {
                _this.$http
                    .get('/admin/api/order/check', {
                        params: {
                            id: _this.downloadId, //this.downloadId
                            type: type,
                            resourceType: 'literature'
                        },
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    })
                    .then(res => {
                        _this.codeNum = res.data.code
                        if (res.data.code == 0) {
                            resolve(res)
                        } else {
                            reject(res.data)
                        }
                    })
            })
            return promise
        }
    },
    created() {
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }

        this.getData('kfhq_lbt') //轮播图
        // 开放获取  文章和图书的 年份筛选参数  bookReleaseDate  ，find接口
        // 学科参数 使用  classifyId
        // 学科分类 接口  http://localhost:10667/admin/api/classify/xk     get
        // 把选中的学科的 id 传到 find 接口的 classifyId 字段上，要是支持多选，就是用 逗号隔开
        // 分类的筛选需要重建索引，现在还不能生效
        this.getListData('ARTICLE')
        this.getListData('BOOK')
        this.getData('kfhq_dt') //动态
        this.getData('kfhq_qtzy') //其他资源
        this.getData('kfhq_jj') //开放获取简介
    }
}
</script>

<style lang="scss" scoped>
.oa-page {
    .top-banner-wrap {
        width: 100%;
        background: #cb2f3c;
        min-height: 300px;
    }
    .oa-main {
        .top-banner-wrap {
            width: 100%;
            background: #cb2f3c;
            min-height: 300px;
            .top-banner-component {
                display: flex;
                justify-content: space-between;
                .left-top-banner {
                    width: 380px;
                    height: 250px;
                    margin-top: 25px;
                    padding: 18px;
                    box-sizing: border-box;
                    position: relative;
                    z-index: 0;
                    h5 {
                        font-size: 22px;
                        line-height: 22px;
                        color: #fff;
                        margin-bottom: 12px;
                    }
                    div {
                        z-index: 10;
                        .banner-content {
                            font-size: 14px;
                            line-height: 26px;
                            color: #fff;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            -webkit-box-orient: vertical;
                        }
                        span {
                            font-size: 16px;
                            color: #fcbb0b;
                            float: right;
                            margin-top: 4px;
                            cursor: pointer;
                        }
                    }
                    .left-top-banner-bg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 380px;
                        height: 250px;
                        background-image: url('../assets/img/bannerbg.png');
                        background-size: 100% 100%;
                        opacity: 0.4;
                        z-index: -1;
                    }
                }
            }
        }
        .dynamic-wrap {
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 10px 30px 0;
            margin-top: 23px;
            .dynamic {
                margin-top: 17px;
                margin-bottom: 21px;
                background: url(../assets/img/border1.png);
                background-size: 1px 50%;
                background-repeat: repeat-y;
                background-position: 50% 0%;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        width: 569px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;
                        margin-bottom: 18px;
                        p {
                            position: relative;
                            width: 422px;
                            font-size: 18px;
                            line-height: 18px;
                            padding-left: 10px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            box-sizing: border-box;
                            a {
                                color: #333;
                                cursor: pointer;
                            }
                        }
                        p::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 8px;
                            width: 4px;
                            height: 4px;
                            line-height: 18px;
                            border-radius: 50%;
                            background: #ffac13;
                        }
                        span {
                            display: block;
                            font-size: 18px;
                            width: 112px;
                            text-align: left;
                            margin-left: 10px;
                        }
                    }
                    li:nth-child(2n + 1) {
                        padding-right: 27px;
                    }
                    li:nth-child(2n + 2) {
                        padding-left: 39px;
                    }
                    li:nth-last-child(2) {
                        // margin-bottom: 0;
                    }
                    li:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .article-wrap {
            padding: 10px 30px 0;
            margin: 26px auto;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        }
        .book-wrap {
            padding: 10px 30px 0;
            margin-bottom: 26px;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        }
        .source-wrap {
            margin-bottom: 30px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            padding: 10px 30px 26px;
            box-sizing: border-box;
            .com-title {
                border-bottom: none;
                margin-bottom: 10px;
            }
            .source {
                li {
                    width: 1138px;
                    display: flex;
                    align-items: center;
                    padding: 16px 0 15px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    border-bottom: 1px dashed #e6e6e6;
                    span {
                        background: #bd1a2d;
                        width: 26px;
                        height: 26px;
                        line-height: 26px;
                        color: #fff;
                        font-size: 10px;
                        text-align: center;
                        border-radius: 4px;
                        margin-right: 6px;
                    }
                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
.pagination-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 30px;
}
</style>